import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Button } from '../../components/styled';
import { Layout, SEO } from '../../layout';
import { Input, InputGroup, Section, Link } from '../../components';
import Assets from './components/assets';
import Boilerplates from './components/boilerplates';
import Founders from './components/founders';
import KeyStats from './components/key-stats';

const Press: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isValid, setValid] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  useEffect(() => {
    const valid = email.match(/[^@]+@[^\.](.+)?\.(.+)?/);
    setValid(!!valid);
    setSubmitEnabled(!!valid);
  }, [email]);

  return (
    <Layout>
      <SEO title="Press" />
      <Section className="inner">
        <h1>Press</h1>
        <p>
          Welcome to the Fidel API press resources page. Our latest brand
          assets, including Fidel API logos, product images, key stats,
          executive team bios and more are available below.
        </p>
        <p>
          For additional media inquiries, please reach out to{' '}
          <Link to="mailto:press@fidelapi.com" target="_blank">
            press@fidelapi.com
          </Link>
          .
        </p>
        <Boilerplates />
        <DownloadForm
          id="press-kit-form"
          method="get"
          action={isValid ? '/Fidel_press_kit.zip' : undefined}
        >
          <p>
            <strong>Download our press kit</strong>
          </p>
          <InputGroup>
            <Input
              label="Your e-mail"
              type="email"
              name="download-kit-email"
              placeholder="you@company.com"
              onChange={e => {
                setEmail(e.target.value);
              }}
              data-testid="dl-kit-input"
            />
            <Button
              type="submit"
              disabled={!submitEnabled}
              data-testid="press-kit-download"
              onClick={
                isValid
                  ? undefined
                  : (e: React.MouseEvent) => e.preventDefault()
              }
            >
              Download
            </Button>
          </InputGroup>
        </DownloadForm>
      </Section>
      <Assets />
      <KeyStats />
      <Founders />

      <Section className="centered">
        <p>
          For media inquiries, please contact{' '}
          <Link to="mailto:press@fidelapi.com" target="_blank">
            <strong>press@fidelapi.com</strong>
          </Link>
        </p>
      </Section>
    </Layout>
  );
};

const DownloadForm = styled.form`
  margin: 60px 0 0;
`;

export default Press;
