import React from 'react';

import { Section } from '../../../components';
import { Strong } from '../../../components/styled';

import DownloadIcon from '../assets/download_icon.svg';
import FidelBlackLogo from '../../../static/images/fidel/logo-full-black.svg';
import FidelWhiteLogo from '../../../static/images/fidel/logo-full-white.svg';

interface File {
  name: string;
  description: string;
}

const Assets: React.FC = () => {
  const files: File[] = [
    { name: 'logo.zip', description: 'logo' },
    { name: 'product.zip', description: 'product images' },
    { name: 'founders.zip', description: 'founder photos' },
    { name: 'team.zip', description: 'team photos' },
  ];

  return (
    <Section className="wide">
      <h2 className="text-left smaller-h2">Brand assets</h2>
      <div className="two-columns">
        <div className="one-third">
          {files.map(({ name, description }: File) => (
            <p key={description} className="download-link">
              <Strong as="a" weight={500} download href={`/press_kit/${name}`}>
                Download {description} <DownloadIcon />
              </Strong>
            </p>
          ))}
        </div>
        <div className="logo-box-container">
          <div className="logo-box">
            <FidelBlackLogo />
          </div>
          <div className="logo-black-box">
            <FidelWhiteLogo />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Assets;
