import React from 'react';
import styled from 'styled-components';

import { Section } from '../../../components';
import { Offscreen, Strong } from '../../../components/styled';
import DownloadIcon from '../assets/download_icon.svg';
import LinkedinIcon from '../assets/linkedin.svg';

interface Founder {
  name: string;
  pos: string;
  linkedin: string;
  bio: string;
  photo: string;
}

const Founders: React.FC = () => {
  const founders: Founder[] = [
    {
      name: 'Dev Subrata',
      pos: 'CEO',
      linkedin: 'dev-subrata',
      bio: 'Dev is committed to driving innovation in the fintech space by giving consumers the ability to easily share their financial data with service providers of their choice. An entrepreneur from young age, Dev joined Badoo as an early employee and played a crucial role in growing its revenue from £0 to £220 million in just over 4 years. Originally from Sweden, Dev is a firm believer in openness. He believes the future of data belongs to the data creator, and that by empowering data creators, innovation and commercial cooperation are natural by-products.',
      photo: 'Dev',
    },
    {
      name: 'Andre Elias',
      pos: 'CTO',
      linkedin: 'engandreelias',
      bio: 'Andre sets the company’s long term technological vision, and drives Fidel API’s mission to make consumer financial data accessible to developers. Originally from Portugal, Andre holds a Masters degree in software engineering, and has published several scientific papers on big data architectures. Andre believes that data availability is crucial to power the next generation of machine learning applications.',
      photo: 'Andre',
    },
  ];

  return (
    <Section className="wide">
      <h2 className="text-left smaller-h2">Founders</h2>
      {founders.map(({ name, photo, pos, linkedin, bio }: Founder) => (
        <div key={name} className="founder-bio">
          <div className="two-columns">
            <div className="one-third">
              <img
                src={getAsset(`Fidel_founders_${photo}`).src}
                srcSet={getAsset(`Fidel_founders_${photo}`).srcSet}
                className="founder-img"
              />
            </div>
            <div className="founder-bio-text">
              <div className="founder-name">
                <h3>
                  {name} <Strong weight={400}>— {pos}</Strong>
                </h3>
                <IconLink
                  href={`https://www.linkedin.com/in/${linkedin}/`}
                  className="link-primary"
                >
                  <Offscreen>{`View ${name}’s LinkedIn profile`}</Offscreen>
                  <LinkedinIcon />
                </IconLink>
              </div>
              <p>{bio}</p>
            </div>
          </div>
        </div>
      ))}
      <p>
        <Strong
          as="a"
          weight={500}
          download
          className="download-link"
          href="/press_kit/founders.zip"
        >
          Download founder photos <DownloadIcon />
        </Strong>
      </p>
    </Section>
  );
};

const IconLink = styled.a`
  border-bottom: none;

  &::after {
    display: none;
  }
`;

function getAsset(name: string) {
  const src = require(`../assets/${name}.png`).default;
  const src2x = require(`../assets/${name}@2x.png`).default;
  const srcSet = `${src}, ${src2x} 2x`;

  return { src, srcSet };
}

export default Founders;
