import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../../components/styled';

export default function Boilerplates() {
  const boilerplate = (
    <>
      <strong>
        Fidel API is a global financial infrastructure platform that enables
        developers to build programmable experiences at the moment a transaction
        occurs on any payment card. Startups through global enterprises are
        leveraging Fidel API’s tools to power a range of solutions including
        digital receipts, customer attribution, loyalty and rewards.
      </strong>
      <p>
        Launched in 2018, Fidel API is headquartered in London, with offices in
        Lisbon, New York, and remote employees globally. Fidel API is backed by
        investors including Bain Capital Ventures, NYCA Partners, QED Investors,
        Citi Ventures, RBC Ventures and Commerce Ventures.
      </p>
    </>
  );

  return <Boilerplate title="Boilerplate" content={boilerplate} />;
}

interface BoilerplateProps {
  title: string;
  content: React.ReactNode;
}

const Boilerplate: React.FC<BoilerplateProps> = ({ title, content }) => (
  <>
    <Title>{title}</Title>
    <StyledText as="p" weight={300} size={24}>
      {content}
    </StyledText>
  </>
);

const Title = styled.h5`
  margin: 40px 0 0;
`;

const StyledText = styled(Heading)`
  line-height: 1.72;

  p {
    font-size: 24px;
  }
`;
