import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Section } from '../../../components';

const KeyStats: React.FC = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            company {
              funding
              offices {
                cities
              }
              employees {
                count
                nationalities
                languages
              }
            }
          }
        }
      }
    `,
  );
  const { funding, offices, employees } = site.siteMetadata.company;

  const stats: Stat[] = [
    { key: 'Team size', value: employees.count },
    { key: 'Team nationalities', value: employees.nationalities },
    { key: 'Team languages', value: employees.languages },
    { key: 'Funding', value: funding },
    { key: 'Offices', value: offices.cities },
  ];

  return (
    <Section className="wide two-columns press-key-stats">
      <h2 className="one-third text-left smaller-h2">Key stats</h2>
      <dl className="key-figures">
        {stats.map(({ key, value }: Stat, idx: number) => (
          <div key={`stat-${idx}`}>
            <dt>{key}</dt>
            <div className="decoration-line" />
            <dd>{value}</dd>
          </div>
        ))}
      </dl>
    </Section>
  );
};

interface Stat {
  key: string;
  value: number | string;
}

export default KeyStats;
